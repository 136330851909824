/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import projects from '../constants/projects';
import ImageStack from './ImageStack';
import InlineButtonList from './InlineButtonList';

import styles from './Layout.module.scss';

const Layout = () => {
  const [active, setActive] = useState('');
  const [isSafari, setIsSafari] = useState(false);

  const handleClick = (id) => {
    if (active !== id) {
      setActive(id);
    }
  };

  const handleClose = () => {
    setActive('exit');

    setTimeout(() => {
      setActive('');
    }, 100);
  };

  useEffect(() => {
    const chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
    let safariAgent = navigator.userAgent.indexOf('Safari') > -1;

    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }

    if (safariAgent !== isSafari) {
      setIsSafari(safariAgent);
    }
  }, []);

  return (
    <main className={classNames(styles.wrapper, { notSafari: !isSafari })}>
      <h1 className={styles.name}>Liz Morrison</h1>
      <div className={styles.topBar}>
        <button
          className={classNames(styles.close, {
            [styles.visible]: active !== '' && active !== 'exit',
          })}
          onClick={handleClose}
          aria-label="Close video"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 21"
            fill="none"
            aria-hidden="true"
          >
            <path
              stroke="#000"
              strokeWidth="2"
              d="M1.018 19.48 19.72.778m0 18.702L1.018.778"
            />
          </svg>
        </button>
      </div>
      <div className={styles.sideBar}>
        <div className={styles.description}>
          <p>
            Hi, it's Liz.
            <br />
            I'm a web engineer with a background in design and over ten years of
            experience at agencies and startups, big and small.
          </p>
          <p className={styles.clients}>
            I've worked on products for brands like{' '}
            <InlineButtonList items={projects} onClick={handleClick} />
          </p>
        </div>
      </div>
      <footer className={styles.bottomBar}>
        <a href="mailto:hi@itsliz.co" className={styles.linkedin}>
          hi@itsliz.co
        </a>
        <a
          href="https://www.linkedin.com/in/liz-morrison-84933422"
          className={styles.linkedin}
        >
          LinkedIn
        </a>
      </footer>

      <ImageStack images={projects} onClick={handleClick} activeId={active} />
    </main>
  );
};

export default Layout;
