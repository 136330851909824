import React from 'react';

import styles from './InlineButtonList.module.scss';

const InlineButtonList = ({ items, onClick }) => {
  return (
    <>
      {items.map((item, i) =>
        i === items.length - 1
          ? [
              `and `,
              <button
                className={styles.button}
                onClick={() => onClick(item.id)}
                key={item.title}
                aria-label={`Open ${item.title} project`}
              >
                {item.title}
              </button>,
              `.`,
            ]
          : [
              <button
                className={styles.button}
                onClick={() => onClick(item.id)}
                key={item.title}
                aria-label={`Open ${item.title} project`}
              >
                {item.title}
              </button>,
              `, `,
            ]
      )}
    </>
  );
};

export default InlineButtonList;
