import aesopImg from '../assets/aesop.jpg';
import notionImg from '../assets/notion.jpg';
import ikeaImg from '../assets/ikea.jpg';
import parenthoodImg from '../assets/roo.jpg';
import aesopVideo from '../assets/aesop.mp4';
import notionVideo from '../assets/notion.mp4';
import ikeaVideo from '../assets/ikea.mp4';
import rooVideo from '../assets/roo.mp4';

const projects = [
  {
    title: 'Notion',
    id: 'notion',
    src: notionImg,
    video: notionVideo,
    alt: 'Notion thumbnail',
    url: 'https://work.co/clients/epic-games/',
  },
  {
    title: 'Ikea',
    id: 'ikea',
    src: ikeaImg,
    video: ikeaVideo,
    alt: 'Ikea thumbnail',
    url: 'https://work.co/clients/ikea/',
  },
  {
    title: 'Planned Parenthood',
    id: 'roo',
    src: parenthoodImg,
    video: rooVideo,
    alt: 'Roo chatbot thumbnail',
    url: 'https://work.co/clients/planned-parenthood/',
  },
  {
    title: 'Aesop',
    id: 'aesop',
    src: aesopImg,
    video: aesopVideo,
    alt: 'Aesop thumbnail',
    url: 'https://work.co/clients/aesop/',
  },
];

export default projects;
