import React from 'react';

import styles from './ImageStack.module.scss';
import classNames from 'classnames';

const ImageStack = ({ images, onClick, activeId }) => {
  return (
    <div className={styles.wrapper}>
      {images.map((image) => (
        <div
          className={classNames([
            styles.item,
            styles[image.id],
            {
              [styles.active]: activeId === image.id,
              [styles.inactive]: activeId !== image.id && activeId !== '',
            },
          ])}
          key={image.id}
        >
          {activeId === image.id && !!image.video && (
            <div className={styles.video}>
              <video
                src={image.video}
                width="100%"
                video="100%"
                style={{ width: '100%', height: '100%' }}
                autoPlay={true}
                loop={true}
                preload="metadata"
                area-label={`${image.title} website demo`}
              />
            </div>
          )}

          <button className={styles.inner} onClick={() => onClick(image.id)}>
            <img
              className={styles.image}
              src={image.src}
              alt={image.alt}
              aria-label={`${image.title} thumbnail`}
            />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ImageStack;
